import { Environment } from './environment.model';

export const environment: Environment = {
    production: true,
    dev: false,
    ASSETS_BASE_URL: 'https://d144inbtkgi5lj.cloudfront.net',
    GATE_URL: 'https://api.wishtrip.com',
    GATE_URL_2: 'https://api2.wishtrip.com',
    MEDIA_URL: 'https://media.wishtrip.com',
    SITE_MEDIA_URL: 'https://site-media.wishtrip.com',
    LIVE_URL: 'https://live.wishtrip.com/loc',
    LIVESHARE_URL: 'https://liveshare.wishtrip.com/loc',
    LIVE_DYNAMIC_MAP_URL: 'https://live.wishtrip.com/dyn_map',
    MARKETING_URL: 'https://www.wishtrip.com/enterprise/',
    CONSOLE_URL: 'https://console.wishtrip.com/',
    B2C_WEBSITE_URL: 'https://www.wishtrip.com',
    B2C_EMBEDDED_URL: 'https://www.wishtrip.com/embedded',
    CATEGORIES_DATA_URL:
        'https://wishtrip-com-assets.s3-eu-west-1.amazonaws.com/environment/prd/ooi-categories',
    GTM_CONTAINER_ID: 'GTM-WHHMVHT',
    ITINERARY_KIOSK_MODE_IDLE_DURATION: 45
};
